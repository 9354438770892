import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import FileUpload from "../../../../content/FileUpload/FileUpload.vue";

export default {
  components: { FileUpload },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Group", route: "/group/navigation-menu" },
      { title: "Upload Quote XLSB to Sharepoint" }
    ]);
  },
  methods: {
    createDialogMessage(data) {
      let sharePointUrl = data.result.sharepoint_url;
      return `Successfully uploaded the file to SharePoint, file can be accessed on: <br /> <a href="${sharePointUrl}" target="_blank">${sharePointUrl}</a>`;
    }
  }
};
